import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, logo, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="96vh"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
        >
          <Header />
          <CFView textCenter column center mt="20px" w="100%">
            <CFView column center>
              <CFImage
                w="100%"
                src={mobileHeroText}
                alt="Time Out Cafe hero text"
                zIndex={98}
              />
            </CFView>
            <CFView mt="25px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          zIndex={90}
          column
        >
          <Header />
          <CFView column justifyCenter alignStart ml="6%" h="100%">
            <CFImage
              w="85%"
              maxWidth="680px"
              src={heroText}
              alt="Time Out Cafe hero text"
              zIndex={98}
            />
            <CFView mt="20px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
