import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, aboutParallax, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView>
          <CFImage src={mobileAbout} w="100%" alt="About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView />
      </DefaultScreen>
    </CFView>
  )
}
