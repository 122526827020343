import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { locationMap, mobileMap } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <CFLink target="_blank" href="https://goo.gl/maps/3SyNxC69H2PEW1vx7">
              <CFImage src={mobileMap} w="100%" alt="Time Out Cafe Map" />
            </CFLink>
          </MobileScreen>
          <DefaultScreen>
            <CFLink target="_blank" href="https://goo.gl/maps/3SyNxC69H2PEW1vx7">
              <CFImage src={locationMap} w="100%" alt="Time Out Cafe Map" />
            </CFLink>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
